const pro=!(window.location.host==='localhost:4000' || window.location.host==='localhost');
const Config = {
    apiGateway: 'https://7kcnu99w41.execute-api.us-east-2.amazonaws.com/pro',
    apiPhp: pro?'https://ganaconcursos.com/apirest':'http://192.168.200.30/apiganaconcursos/index.php/apirest',
    menu: [
        {id:'inicio', label:'Inicio', icon:'pi pi-home', url:'/'},
        {id:'empresa', label:'Empresas', icon:'pi pi-briefcase', url:'/empresas'},
        {id:'concurso', label:'Concursos', icon:'pi pi-money-bill', url:'/concursos'},
        /*{id:'emailmarketing', label:'Email Marketing', icon:'pi pi-envelope', url:'/emailmarketing'},*/
    ],
    urlArchivos: pro?'https://ganaconcursos.com/archivos/concurso/':'http://192.168.200.30/apiganaconcursos/archivos/concurso/',
    plasma: '705c0690b8877332ead51aa0f9c7262c',
}

export default Config;